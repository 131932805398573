<template>
  <section class="auth-container register pt-4">
    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1"> </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2"> </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-alert color="error" v-if="info.message" text type="error">{{
            info.message
          }}</v-alert>

          <div class="mb-4">
            <v-form ref="step-1" v-model="validOne">
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-text-field
                    single-line
                    dense
                    outlined
                    type="text"
                    class="mb-3"
                    placeholder="Nom"
                    v-model="user.lastname"
                    :rules="[].concat(requiredRule)"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-text-field
                    single-line
                    dense
                    outlined
                    type="text"
                    class="mb-3"
                    placeholder="Prénom"
                    v-model="user.firstname"
                    :rules="[].concat(requiredRule)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-text-field
                    single-line
                    outlined
                    type="email"
                    class="mb-3"
                    placeholder="Email"
                    dense
                    v-model="user.email"
                    :rules="[].concat(emailRules)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <!-- <label for="">Statut :</label> -->
                  <v-select
                    :items="statusList"
                    placeholder="Statut"
                    dense
                    outlined
                    v-model="user.status"
                    @change="removeInfo"
                    :rules="[].concat(requiredRule)"
                    item-text="label"
                    item-value="id"
                  ></v-select>
                </v-col>

                <v-col class="py-0" cols="12" v-if="user.status === 3">
                  <v-text-field
                    single-line
                    outlined
                    dense
                    type="text"
                    class="mb-3"
                    placeholder="Précisez"
                    v-model="user.structure"
                    :rules="[].concat(requiredRule)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-btn
            @click="nextStep"
            large
            min-width="155"
            color="primary"
            class="rounded-lg ma-auto d-block"
            >Suivant</v-btn
          >
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-alert v-if="info.message" color="error" text type="error">{{
            info.message
          }}</v-alert>

          <v-form ref="step-2" v-model="validTwo">
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                  single-line
                  dense
                  outlined
                  type="text"
                  class="mb-3"
                  placeholder="Adresse"
                  v-model="user.adress"
                  :rules="[].concat(requiredRule)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                  single-line
                  dense
                  outlined
                  type="text"
                  class="mb-3"
                  placeholder="Code postal"
                  v-model="user.cedex"
                  :rules="[].concat(requiredRule)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                  single-line
                  dense
                  outlined
                  type="text"
                  class="mb-3"
                  placeholder="Ville"
                  v-model="user.city"
                  :rules="[].concat(requiredRule)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex">
            <v-btn
              @click="
                step = 1
                info.message = null
              "
              small
              class="rounded-lg ma-auto d-block"
              >Retour</v-btn
            >
            <v-btn
              @click="nextStep"
              large
              color="primary"
              class="rounded-lg ma-auto d-block"
              >Suivant</v-btn
            >
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-form ref="step-3" v-model="validThree">
            <v-row>
              <v-alert v-if="info.message" color="error" text type="error">{{
                info.message
              }}</v-alert>
              <v-alert color="primary" class="mx-3" text type="info">
                Le mot de passe doit contenir au moins 8 caractères, une lettre
                majuscule et un chiffre.
              </v-alert>

              <v-col class="py-0" cols="12">
                <v-text-field
                  single-line
                  dense
                  outlined
                  type="password"
                  class="mb-3"
                  placeholder="Mot de passe"
                  v-model="user.password"
                  :rules="[].concat(passwordRule)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                  single-line
                  dense
                  outlined
                  type="password"
                  class="mb-3"
                  placeholder="Confirmation"
                  v-model="user.confirm"
                  :rules="[].concat(passwordRule)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-btn
            @click="nextStep"
            large
            color="primary"
            class="rounded-lg ma-auto d-block"
            :loading="registerLoading"
          >
            Valider ma demande</v-btn
          >
          <v-btn
            @click="
              step = 2
              info.message = null
            "
            small
            class="rounded-lg ma-auto d-block mt-4 mb-4"
            >Retour</v-btn
          >
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="isVerifModalOpen" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 green--text darken-1 text-center">
          Inscription validée
        </v-card-title>
        <v-card-text
          >Merci pour votre inscription. Vous allez bientôt recevoir un mail de
          confirmation à votre adresse <strong>{{ user.email }} </strong>. Vous
          pourrez ensuite accéder à la BD interactive.

          <br />
          <br />
          <small
            >Si vous ne trouvez pas le mail, pensez à consulter vos
            spams.</small
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$router.push('/')">
            J'ai compris
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import dptList from '@/assets/departments.json'
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
  data: () => ({
    validOne: false,
    validTwo: false,
    validThree: false,
    user: {
      status: null,
      firstname: null,
      lastname: null,
      email: null,
      adress: null,
      cedex: null,
      city: null,
      structure: null,
      reason: null,
      password: null,
      confirm: null
    },
    registerLoading: false,
    isVerifModalOpen: false,
    requiredRule: [v => !!v || 'Veuillez remplir ce champ'],
    email: '',
    emailRules: [
      v => !!v || 'Veuillez entrer un email',
      v => /.+@.+/.test(v) || "L'email doit être valide"
    ],
    passwordRule: v =>
      /([a-zA-z0-9]){8,}/.test(v) ||
      'Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule et un chiffre.',
    statusList: [
      { id: 1, label: 'Locataire' },
      { id: 2, label: 'Copropriétaire' },
      { id: 3, label: 'Autre' }
    ],
    // dptList: dptList,
    step: 1,
    info: { message: null, color: null },
    user_id: null
  }),
  computed: {
    departements () {
      const array = []
      dptList.forEach(element => {
        const elemCopy = { ...element }
        elemCopy.name = element.code + ' - ' + element.name
        array.push(elemCopy)
      })
      return array
    }
  },
  methods: {
    ...mapActions('Auth', ['updateMotiv', 'inscription']),
    log (data) {
      return data
    },
    customFilter (item, queryText, itemText) {
      if (
        itemText.toLowerCase().includes(queryText.toLowerCase()) ||
        item.code.includes(queryText)
      ) {
        return true
      }

      return false
    },
    async nextStep () {
      switch (this.step) {
        case 1:
          this.$refs['step-1'].validate()
          if (this.validOne) this.step++
          this.info = { message: null, color: null }
          break
        case 2:
          this.$refs['step-2'].validate()
          if (this.validTwo) this.step++
          this.info = { message: null, color: null }

          break
        case 3:
          this.$refs['step-3'].validate()
          this.info = { message: null, color: null }

          if (this.validThree) {
            if (this.user.password === this.user.confirm) {
              try {
                this.registerLoading = true
                await this.inscription(this.user)
                this.registerLoading = false
                this.isVerifModalOpen = true
              } catch (e) {
                setTimeout(() => {
                  this.registerLoading = false
                }, 500)

                if (e.code && e.code === 1) {
                  this.step = 1
                  this.info.message = e.message
                  this.info.color = 'red'
                } else {
                  this.info.message = e.message
                  this.info.color = 'red'
                }
              }
            } else {
              this.info = {
                color: 'red',
                message: 'Les mots de passe ne sont pas identiques.'
              }
            }
          }
          break

        default:
          break
      }
    },
    removeInfo () {
      if (this.user.status === 1 || this.user.status === 2) {
        this.user.structure = null
      } else if (this.user.status === 3) {
        this.user.adress = null
        this.user.city = null
        this.user.cedex = null
      }
    }
  }
}
</script>

<style></style>
