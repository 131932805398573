<template>
  <div class="modal-wrap auth-wrap">
    <div class="auth-modal modal">
      <div class="auth-switcher">
        <a
          @click="changeModalComponent('login')"
          :class="current === 'login' ? 'active' : null"
          >Connexion</a
        >
        <a
          @click="changeModalComponent('register')"
          :class="current === 'register' ? 'active' : null"
          >Inscription</a
        >
      </div>
      <transition name="fade">
        <Login
          @goForgot="changeModalComponent('forgot')"
          v-if="current === 'login'"
        />
      </transition>
      <transition name="fade">
        <Register v-if="current === 'register'" />
      </transition>

      <transition name="fade">
        <CreatePassword v-if="current === 'password'" />
      </transition>
      <transition name="fade">
        <ForgotPassword v-if="current === 'forgot'" />
      </transition>
    </div>
    <div v-if="!isMobile" class="logos-bg ">
      <div class="logo-container py-1">
        <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="" />
      </div>
      <div class="logo-container">
        <img src="@/assets/images/logo_action_logement.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Login from './Login'
import Register from './Register'
import Activate from './Activate'
import ForgotPassword from './ForgotPassword'
import CreatePassword from './CreatePassword'
export default {
  components: {
    Login,
    Activate,
    Register,
    ForgotPassword,
    CreatePassword
  },
  props: ['isOpen'],
  data () {
    return {
      current: 'login'
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  mounted () {
    if (!localStorage.getItem('firstTime')) {
      this.changeModalComponent('activate')
      localStorage.setItem('firstTime', true)
    }
  },
  methods: {
    changeModalComponent (componentName) {
      this.current = null
      setTimeout(() => {
        this.current = componentName
      }, 550)
    }
  }
}
</script>
