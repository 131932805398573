<template>
  <section class="sandbox">
    <h1>Salut sandbox</h1>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="scss">
  .sandbox {
    overflow: auto;
    height: 100%;
  }

</style>
