<template>
    <div class="auth-container login">
       <div class="auth-header pt-8">
            <img src="@/assets/images/onv_logo.png" class="mx-auto d-block" alt="Illustration"/>
       </div>
        <v-container fluid class="auth-form">
          <transition name="fade" mode="out-in">
                        <v-alert
                        v-if='isError'
      color="red lighten-2"
      dark
      class="text-center"
    >
      Votre adresse mail ou votre mot de passe est incorrect
    </v-alert>
          </transition>
          <v-form v-model="valid" @submit.prevent='handleSignIn'>

          <label>Email :</label>
          <v-text-field
              single-line
              outlined
              type='email'
              full-width
              dense
              :rules="[v => !!v || 'Veuillez remplir ce champ']"
              v-model='mail'
            ></v-text-field>
            <label>Mot de passe :</label>
            <v-text-field
              single-line
              outlined
              full-width
              :type='show ? "text" : "password"'
              :rules="[v => !!v || 'Veuillez remplir ce champ']"
              dense
              v-model='password'
              hide-details
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
            <div class="d-flex align-center justify-center">
              <v-checkbox
              v-model="remember_me"
              label="Rester connecté"
              color="primary"

              hide-details
            ></v-checkbox>
            </div>
            <v-btn type="submit" large min-width="155" color="primary" class="mx-auto mb-4 d-block rounded-lg">Se connecter</v-btn>
          </v-form>

          <a @click="$emit('goForgot')" class='form-link'>Mot de passe oublié?</a>

        </v-container>
         <div class="logos mb-5 d-flex justify-space-between align-center">
          <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="">
          <img src="@/assets/images/logo_action_logement.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      mail: '',
      password: '',
      valid: false,
      remember_me:false,
      error:false,
      show: false
    }
  },
  methods: {
    async handleSignIn () {
      if (!this.valid) return
      const user = await this.$store.dispatch('Auth/signIn', { username: this.mail.toLowerCase(), password: this.password, remember: this.remember_me })
      if (this.isLoggedIn) {
    
        this.$router.push({ name: 'intro' })
      }
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['Auth/user']
    },
    isLoggedIn () {
      return this.$store.getters['Auth/isLoggedIn']
    },
    isError() {
      return this.$store.getters['Auth/error']

    },
  }
}
</script>
