<template>
 
  <div class="modal-wrap auth-wrap">
  <div class="auth-modal modal">
    <section class="auth-container register">
      <div class="d-flex flex-column">
        <p v-if="messageObj" :class="messageObj.error ? 'red--text' : 'green--text'">{{messageObj.message}}</p>
       <v-form @submit.prevent="sendPassword">
          <v-text-field
          outlined
          hide-details
          type='password'
          class="mb-3 input"
          placeholder="Entrez votre mot de passe"
          v-model='password'
        ></v-text-field>
        <v-btn color="primary" class="ma-auto" type="submit">Valider</v-btn>
       </v-form>
      </div>
    </section>
  </div>
      <div class="logos-bg ">
        <div class="logo-container py-1">
          <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="">
        </div>
        <div class="logo-container">
          <img src="@/assets/images/logo_action_logement.png" alt="">
        </div>
      </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'


export default {
  data: () => ({
    password: null,
    messageObj: null
  }),
  created () {
  },
  methods: {
    async sendPassword() {
      if(!this.password) return
      try {
        let response = await this.axios.post(`${process.env.VUE_APP_API}auth/adminregister/${this.$route.params.code}`, {password: this.password})
        this.messageObj = {message: "Votre mot de passe a bien été enregistré vous allez être redirigé", error: false}

        setTimeout(() => {
          this.$router.push('/auth')
        }, 3500);
        
      } catch (err) {
        this.messageObj = {message: "Error", error: true}
        
      }
    }
  },
}
</script>

<style>

</style>
