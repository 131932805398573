<template>
 <div class="auth-container forgot-password">
       <div class="auth-header mt-8">
          <img src="@/assets/images/onv_logo.png" class="mx-auto d-block" alt="Illustration"/>
       </div>
        <v-container fluid class="auth-form">
          <p v-if="info.message" :class="`${info.color}--text caption`">{{info.message}}</p>
          <v-form v-model="isValid" @submit.prevent="sendForgotEmail">
          <label>Email :</label>
          <v-text-field
              single-line
              outlined
              type='email'
              full-width
              dense
              :rules="[].concat(emailRules)"
              v-model='email'
            ></v-text-field>

            <v-btn type="submit" large color="primary" class="mx-auto mb-4 d-block rounded-lg" >Récupérer mon mot <br> de passe</v-btn>
          </v-form>
        </v-container>
    </div>
</template>

<script>
export default {
  data: () => ({
    email: "",
    emailRules: [
      v => !!v || 'Veuillez entrer un email',
      v => /.+@.+/.test(v) || "L'email doit être valide",
    ],
    isValid: false,
    info: {message: "", color: ""}
  }),
  methods: {
    async sendForgotEmail () {
      if(this.isValid) {
        try {
          const forgotRes = await this.axios.post(`${process.env.VUE_APP_API}auth/reset`, {
            username: this.email
          })
          this.info.message = "Un email de réinitialisation de mot de passe vous a été envoyé"
          this.info.color = "green"
          
        } catch (error) {
          this.info.color = "red"
          if(error.response && error.response.data.type === "UserNotFoundException") {
            this.info.message = "Aucun compte n'est lié à ce mail"
          } else if (error.response && error.response.data.message === "User not found") {
            this.info.message = "Aucun compte n'est lié à cet email"
          }
        }
        // this.email = null
      }
    }
  },
}
</script>