<template>
  <section class="auth-container register activate">
    <div class="auth-header pt-8">
      <img
        src="@/assets/images/onv_logo.png"
        class="mx-auto d-block"
        alt="Illustration"
      />
    </div>
    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-items>
        <v-stepper-content step="1">
          <span v-if="errorMessage" class="red--text text-caption">{{
            errorMessage
          }}</span>
          <v-form
            @submit.prevent="validateCode"
            class="auth-form d-flex flex-column"
          >
            <label>Valider mon inscription :</label>
            <v-text-field
              outlined
              hide-details
              type="text"
              class="input"
              placeholder="Entrer le code d'inscription"
              v-model="code"
            ></v-text-field>
            <v-btn color="primary" type="submit" class="mt-5 ma-auto"
              >Valider mon inscription</v-btn
            >

            <a @click="$emit('goToRegister')" class="form-link mt-5"
              >Je n’ai pas reçu de code inscription</a
            >
            <a @click="$emit('goToLogin')" class="form-link mt-5"
              >J'ai déjà activé mon compte avec le code d'inscription et choisi
              mon mot de passe</a
            >
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="d-flex flex-column">
            <p v-if="info.message" :class="`${info.color}--text caption`">
              {{ info.message }}
            </p>
            <v-form
              ref="register-form"
              v-model="valid"
              @submit.prevent="setUserAccount"
              class="py-8"
            >
              <v-text-field
                v-if="!hasEmail"
                outlined
                type="email"
                class="input mb-3"
                label="Définir votre adresse email"
                :rules="[].concat(emailRules)"
                validate-on-blur
                v-model="email"
              ></v-text-field>
              <p class="caption">
                Le mot de passe doit contenir au moins 8 caractères, une lettre
                majuscule et un chiffre
              </p>
              <v-text-field
                outlined
                :type="show ? 'text' : 'password'"
                class="input"
                label="Choisissez un mot de passe"
                :rules="[].concat(passwordRules)"
                validate-on-blur
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
              ></v-text-field>
              <v-checkbox
                class="ma-0"
                :rules="[].concat(requiredRule)"
                v-model="isCguChecked"
              >
                <div slot="label">
                  J'accepte les
                  <a @click="showCGU">conditions d'utilisation du site</a>
                </div>
              </v-checkbox>
              <!-- <v-checkbox
                class="ma-0"
                label="J'accepte de recevoir de l'information de l'ONV"
                v-model="canSendData"
              >
              </v-checkbox> -->
              <v-btn
                :disabled="!!this.info.message"
                color="primary"
                class="ma-auto"
                type="submit"
                >Valider mon inscription</v-btn
              >
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div class="logos d-flex justify-space-between align-center">
      <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="" />
      <img src="@/assets/images/logo_action_logement.png" alt="" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    code: '',
    show: false,
    isFlyer: false,
    email: null,
    password: null,
    step: 1,
    hasEmail: false,
    userInscriptionId: null,
    errorMessage: null,
    emailRules: [
      v => !!v || 'Veuillez entrer un email',
      v => /.+@.+/.test(v) || "L'email doit être valide"
    ],
    passwordRules: [
      v => !!v || 'Veuillez entrer un mot de passe',
      v =>
        /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/.test(v) ||
        'Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule et un chiffre'
    ],
    requiredRule: [
      v => !!v || "Veuillez accepter les conditions d'utilisation du site"
    ],
    valid: false,
    info: { message: '', color: '' },
    isCguChecked: false,
    canSendData: false
  }),
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    ...mapActions('Auth', ['checkDataByCode', 'updateUserInscription']),
    async validateCode () {
      if (!this.code) return
      const normalizedCode = this.code
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      const codeRes = await this.checkDataByCode(normalizedCode)
      console.log('okkk')
      this.tempUser = codeRes
      if (!codeRes) this.errorMessage = "Le code saisi n'existe pas"
      else {
        this.email = codeRes.email
        if (this.email) this.hasEmail = true
        this.userInscriptionId = codeRes.id
        this.step = 2
      }
      // this.$emit('goToPassword')
    },
    async setUserAccount () {
      if (!this.email || !this.password) return
      this.$refs['register-form'].validate()
      if (this.valid) {
        try {
          const {
            data: { type, message }
          } = await this.axios.post(process.env.VUE_APP_API + 'auth/register', {
            email: this.email,
            password: this.password
          })
          if (type === 'User Created') {
            await this.updateUserInscription({
              user: this.tempUser,
              code: this.code,
              email: this.email,
              user_id: message,
              canSendData: this.canSendData
            })
            this.info.message =
              'Votre mot de passe a bien été enregistré, vous allez recevoir un mail de validation'
            this.info.color = 'green'
          } else {
            this.info.message = 'Une erreur est survenue ' + type
            this.info.color = 'red'
          }
        } catch (error) {
          console.log(error)
          this.info.color = 'red'
          if (
            error.response &&
            error.response.data.type === 'UserLambdaValidationException'
          ) {
            this.info.message = 'Cet email existe déja'
          } else {
            this.info.message = 'Une erreur est survenue ' + error
          }
        }
      }
    },
    showCGU () {
      window.open(window.location.origin + '/cgu', '_blank')
    }
  }
}
</script>

<style></style>
